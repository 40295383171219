import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { BehaviorSubject, Subject } from 'rxjs';
import { Observable, firstValueFrom, of } from 'rxjs';
import { outboundConferenceCallInterface, sendTwilioCallActivityStatus, validateOutboundCall } from '../common-components/call-slideup-popover/interface';
import { showWorkers } from './calls/conference-modal/conference-modal-interfaces';
import { checkLatestActivityLogs, guidanceInterface, outstandingBalanceResponse, patientDetailsResult, paymentSuccessfullResponse, paymentScheduledResponse, paymentPlanActivatedResponse, paymentFailedResponse, activityLogChatinterface } from '../patients/patient-profile/patient-profile-interface';
import { getCodeCategory } from '../navbar/interface';
import { invoiceGeneratedResponse } from '../patients/patient-profile/patient-profile-interface';
import { SmsOrEmailTemplateApiResponse } from '../patients/message-modal/message-modal-interfaces';
import { sendData } from './calls/interface';
import { availableWorkers } from './calls/conference-modal/conference-modal-interfaces';
import { taskDetailsRecord } from '../patients/patients-interface';
import { uiClientsConfig } from 'config';
import { PEFFilterModel } from '../patients/patient-engagement-feed/pef-filter-model';

@Injectable({
  providedIn: 'root'
})

export class InboxService {
  currentUrl = window.location.href;
  regex = /(?<=https:\/\/)\w+(?=-focus)/;
  domain: any = (this.currentUrl.match(this.regex)) ? ((this.currentUrl.match(this.regex))?.[0]) : null;
  queueList = `/api/v1/platform/work-queue-lookup/associated-queues/upfocus`;
  inboxFormdata: string = `/api/v1/platform/inbox-focus/createPatientFocusActivityLog/`;
  selectTemplateData = `/api/v1/platform/inbox-focus/getAllPatientFocusActivityLogTemplates`;
  twilioService: string = `https://${this.domain}-twilio.${uiClientsConfig.reservedUrlDomain}/api/twilio-public-endpoints`;
  smsSecureChat: string = `api/v1/platform/inbox-focus/PatientFocusActivityLogSMSAndEmailTrail/all/`;
  twilioCallService: string = `/api/v1/platform/twilio-task-router/get-pending-calls`;
  inboxFocusApiPath: string = "/api/v1/platform/inbox-focus";
  activityLogChat: string = `api/v1/platform/inbox-focus/ActivityLogOutboundSMSAndEMAILTrail/`;
  classificationData: string = `api/v1/platform/inbox-focus/filtered/42c4a3d9-61d2-4872-96fb-979380512367/FOCUSCALL`
  getRoutingTypeApiPath: string = '/api/v1/platform/brand-management-lookup';
  transferCallPath: string = `https://${this.domain}-twilio.${uiClientsConfig.reservedUrlDomain}/api/twilio-public-endpoints/transfer-call`;
  availableWorkers: string = `/api/v1/platform/twilio-task-router/available-workers`;
  createOutboundCallApiPath: string = `https://${this.domain}-twilio.${uiClientsConfig.reservedUrlDomain}/api/twilio-public-endpoints/create-call`;
  intelligentDefaultsData: string = `/api/v1/platform/inbox-focus/getIntelligentDefaultPsychographicGuidance?subDomain=${this.domain}&clientName=`;
  activityLogClassificationData: string = `api/v1/platform/code-value-by-category-lookup/filtered/42c4a3d9-61d2-4872-96fb-979380512387/ETC`;
  generateConsolidatePayment: string = `/api/v1/platform/inbox-focus/generateConsolidatePaymentRequest`;
  inboxTaskListAPI: string = `/api/v1/platform/inbox-tasks/list/`;
  inboxTaskDetailsAPI: string = `/api/v1/platform/inbox-tasks`;
  inboxTaskDetailsAPIV2: string = `/api/v2/platform/inbox-tasks`;
  inboxTaskListPullAPI: string = `/api/v1/platform/inbox-tasks/pull`;
  patientVisitPostAPI: string = `/api/v1/platform/patient-visit`;
  inboxTasksTypeListAPI: string = `/api/v1/platform/code-category-lookup/parent/`; // Inbox Tasks Type list API
  inboxTasksPriorityListAPI: string = `/api/v1/platform/code-value-by-category-lookup/`; // Inbox Tasks Priority List API
  inboxPatientEngagementFeedAPI: string = `/api/v1/platform/patient-engagement-feed`; // Inbox patient-engagement-feed API
  inboxPatientTaskGuidanceDataAPI: string = `/api/v1/platform/task-guidance-data`; // Inbox patientTaskGuidance data API
  inboxChangeTaskStatusAPI: string = `/api/v1/platform/inbox-tasks/change-task-status`; // Inbox Task Change Status API.
  inboxUpdateTaskBookmarkStatusAPI: string = `/api/v1/platform/inbox-tasks/update-bookmark-status`; // Inbox Task Bookmark Status API.

  private tabIndex: number = 0;
  private fromPhoneNumber: string = "";
  toTwilioNumber: string = "";
  isIncomingCall: boolean = false;
  taskSid: string = "";
  callCompletedWorkerTask: any
  isOpenConferenceModal: boolean = false;
  color: any
  private closeModalSubject = new Subject<boolean>();
  closeModal$ = this.closeModalSubject.asObservable();
  dialWorker: any[] = [];
  headers;
  constructor(private http: HttpClient) {
    this.headers = new HttpHeaders()
      .set('Content-Type', 'application/json;charset=utf-8')
  }
  generateConsolidatePaymentRequest(consolidatePaymentGenerateObject: any) {
    const httpOptions = {
      headers: new HttpHeaders({
        'content-type':  'application/json;charset=UTF-8',
      })
    };
    return this.http.post(this.generateConsolidatePayment, consolidatePaymentGenerateObject, httpOptions);
  }
    getLatestSnoozeData(orgEntityId: string) {
    return this.http.get(this.inboxFocusApiPath + "/getSnoozeStatus", {params: {orgEntityId}})
  }
  getQueueList(type: any): Observable<any> {
    return this.http.get(this.queueList, { params: { type: type } });
  }

  getSelectTemplateData(): Observable<SmsOrEmailTemplateApiResponse> {
    return this.http.get<SmsOrEmailTemplateApiResponse>(this.selectTemplateData);
  }

  getTwilioPendingTasks({ limit = 20, twilioWorkFlowId }: any): Observable<any> {
    return this.http.post(this.twilioService + "/pending-tasks", { limit, twilioWorkFlowId });
  }

  getTwilioCalls() {
    return this.http.get(this.twilioCallService, { params: { isTesting: true } });
  }

  updateIncomingCallWithWorkerAttribute(callObjectDetails: any) {
    return this.http.post(this.twilioService + "/pick-call", callObjectDetails)
  }

  getPatientCallsDetails(dataObject: any) {
    return firstValueFrom(this.http.post(this.inboxFocusApiPath + "/patientInfoMapping", dataObject)
      .pipe(responseData => {
        return responseData;
      }))
  }

  postPatientVisit(dataObject: any) {
    return this.http.post(this.patientVisitPostAPI , dataObject)
  }

  sendFormData(tab: string, orgEntityId: string, dataObject: any) {
    // const headers = new HttpHeaders({
    //   'Content-Type': 'multipart/form-data; boundary=' + dataObject.boundary,
    // });
    return firstValueFrom(this.http.post<sendData>(this.inboxFormdata + `${tab}/${orgEntityId}`, dataObject,)
      .pipe(responseData => {
        return responseData;
      }))
  }

  getSmsSecureChat(orgId: string) {
    return this.http.get(this.smsSecureChat + `${orgId}`);
  }

  getPatientMessagingDetails(id: string) {
    return firstValueFrom(this.http.get(this.inboxFocusApiPath + `/getAllPatientData/${id}`)
      .pipe(responseData => {
        return responseData;
      }))
  }

  getFocusWorkQueues(pageNumber: number,) {
    const obj = { consultType: "QUICK_CONSULT", limit: 4, pageNumber: pageNumber, sortBy: "workQueueName", sortOrder: "ASC" }
    return this.http.get(this.inboxFocusApiPath + "/getFocusWorkQueues", { params: obj })
  }

  getFocusProvider(pageNumber: number, workQueueId: string) {
    const obj = { limit: 4, pageNumber: pageNumber, sortBy: "providerName", sortOrder: "ASC", workQueueId: workQueueId }
    return this.http.get(this.inboxFocusApiPath + "/focus-provider", { params: obj });
  }

  getInvoiceGenerated(patientPhoneNumber: string, startDate: string, endDate: string, orgEntityId: string | null): Observable<invoiceGeneratedResponse> {
    const obj: any = { patientPhoneNumber: patientPhoneNumber, startDate: startDate, endDate: endDate, orgEntityId: orgEntityId }
    return this.http.get<invoiceGeneratedResponse>(this.inboxFocusApiPath + "/getInvoiceGenerated", { params: obj })
  }

  getPaymentScheduled(patientPhoneNumber: string, startDate: string, endDate: string, orgEntityId: string | null): Observable<paymentScheduledResponse> {
    const obj: any = { patientPhoneNumber: patientPhoneNumber, startDate: startDate, endDate: endDate, orgEntityId: orgEntityId }
    return this.http.get<paymentScheduledResponse>(this.inboxFocusApiPath + "/getPaymentScheduled", { params: obj })
  }

  getConsolidatedPaymentScheduled(patientPhoneNumber: string, startDate: string, endDate: string, orgEntityId: string | null) {
    const obj = { patientPhoneNumber: patientPhoneNumber, startDate: startDate, endDate: endDate, orgEntityId: orgEntityId }
    return firstValueFrom(this.http.get(this.inboxFocusApiPath + "/getConsolidatedPaymentScheduled", { params: obj }).pipe(responseData => {
      return responseData;
    }))
  }

  getIsProviderSideConsolidated(patientPhoneNumber: string, orgEntityId: string | null, subDomain: string,) {
    const obj = { patientPhoneNumber: patientPhoneNumber, orgEntityId: orgEntityId, subDomain: subDomain }
    return firstValueFrom(this.http.get(this.inboxFocusApiPath + "/isProviderSideConsolidated", { params: obj }).pipe(responseData => {
      return responseData;
    }))
  }

  getPaymentPlanActivated(patientPhoneNumber: string, startDate: string, endDate: string, orgEntityId: string | null): Observable<paymentPlanActivatedResponse> {
    const obj: any = { patientPhoneNumber: patientPhoneNumber, startDate: startDate, endDate: endDate, orgEntityId: orgEntityId }
    return this.http.get<paymentPlanActivatedResponse>(this.inboxFocusApiPath + "/getPaymentPlanActivated", { params: obj })
  }

  getPaymentSuccessfull(patientPhoneNumber: string, startDate: string, endDate: string, orgEntityId: string | null): Observable<paymentSuccessfullResponse> {
    const obj: any = { patientPhoneNumber: patientPhoneNumber, startDate: startDate, endDate: endDate, orgEntityId: orgEntityId }
    return this.http.get<paymentSuccessfullResponse>(this.inboxFocusApiPath + "/getPaymentSuccessfull", { params: obj })
  }

  getOutStandingBalance(patientPhoneNumber: string, startDate: string, endDate: string, orgEntityId: string | null, isComplete: boolean): Observable<outstandingBalanceResponse> {
    const obj: any = { patientPhoneNumber: patientPhoneNumber, startDate: startDate, endDate: endDate, orgEntityId: orgEntityId, isComplete: isComplete }
    return this.http.get<outstandingBalanceResponse>(this.inboxFocusApiPath + "/getOutStandingBalance", { params: obj })
  }

  getPaymentFailed(patientPhoneNumber: string, startDate: string, endDate: string, orgEntityId: string | null): Observable<paymentFailedResponse> {
    const obj: any = { patientPhoneNumber: patientPhoneNumber, startDate: startDate, endDate: endDate, orgEntityId: orgEntityId }
    return this.http.get<paymentFailedResponse>(this.inboxFocusApiPath + "/getPaymentFailed", { params: obj })
  }

  getCallPickedDetail(phoneNumber: string, orgEntityId: string): Observable<patientDetailsResult> {
    const obj = { patientPhoneNumber: phoneNumber, orgEntityId: orgEntityId }
    return this.http.get<patientDetailsResult>(this.inboxFocusApiPath + "/getPatientAndGuarantorInfo", { params: obj })
  }
  getInboxTaskDetails(interventionId: string): Observable<taskDetailsRecord> {
    return this.http.get<taskDetailsRecord>(this.inboxTaskDetailsAPI +'/'+interventionId)
  }

  setCurrentDial(time: any, worker: availableWorkers) {
    console.log(time);
    console.log(worker);
    this.dialWorker.push({ time: time, worker: worker });
  }

  getCurrentDial() {
    return this.dialWorker;
  }

  isDialPick(worker: any[]) {
    const dial = this.dialWorker.filter(obj => !worker.some(item => item.oauthUserId === obj.worker.oauthUserId));
    console.log(dial);
    this.dialWorker = dial;
  }

  isTransferEnabled(val: any) {
    this.closeModalSubject.next(val);
  }

  setTabIndex(index: number) {
    this.tabIndex = index;
  }

  getTabIndex() {
    return this.tabIndex;
  }

  setPatientInitialColor(color: any) {
    this.color = color;
  }

  getPatientInitialColor() {
    return this.color;
  }

  setPhoneNumber(number: string) {
    this.fromPhoneNumber = number
  }

  setCallCompletedWorkerTask(task: any) {
    this.callCompletedWorkerTask = task;
  }

  getCallCompletedWorkerTask(): Observable<string> {
    return of(this.callCompletedWorkerTask);
  }

  private incomingNumber = new BehaviorSubject<string | undefined>("");
  currentNumber = this.incomingNumber.asObservable();
  changeIncomingNumber(newNumber: string | undefined) {
    this.incomingNumber.next(newNumber);
  }

  getPhoneNumber() {
    return this.fromPhoneNumber;
  }

  setTwilioNumber(number: string) {
    this.toTwilioNumber = number;
  }

  getTwilioNumber() {
    return this.toTwilioNumber
  }

  setTaskSid(number: string) {
    this.taskSid = number;
  }

  getTaskSid() {
    return this.taskSid
  }

  setOpenConference(isOpen: boolean) {
    this.isOpenConferenceModal = isOpen;
  }

  getOpenConference() {
    return this.isOpenConferenceModal
  }

  getActivityLogChat(orgId: string, pageNumber: number) {
    return firstValueFrom(this.http.get<activityLogChatinterface>(this.activityLogChat + `${orgId}` + `/${pageNumber}/`)
      .pipe(responseData => {
        return responseData;
      }))
  }



  getClassificationData() {
    return firstValueFrom(this.http.get<getCodeCategory>(this.classificationData)
      .pipe(responseData => {
        return responseData;
      }))
  }

  getActivityLogClassificationData() {
    return firstValueFrom(this.http.get<getCodeCategory>(this.activityLogClassificationData)
      .pipe(responseData => {
        return responseData;
      }))
  }

  getRoutingType(subDomain: string, tabType: string) {
    return this.http.post(this.getRoutingTypeApiPath + `/${subDomain}/${tabType}`, { "configObjects": ["brandManagementMiscellaneousConfiguration"] });
  }

  transferCall(twilioWorkerId: string | null, externalAgentNumber: string, twilioNumber: string, taskSid: string, orgEntityId: string) {
    return firstValueFrom(this.http.post(this.transferCallPath, { twilioWorkerId, externalAgentNumber, twilioNumber, taskSid, orgEntityId })
      .pipe(responseData => {
        return responseData;
      }))
  }

  conferenceCall(workerSid: string, taskSid: string, externalAgentNumber: string, orgEntityId: string) {
    return this.http.post(this.twilioService + "/conference-participant", {
      workerSid: workerSid,
      taskSid: taskSid,
      externalAgentNumber: externalAgentNumber,
      orgEntityId: orgEntityId
    });
  }

  getAvailableWorkers(): Observable<showWorkers> {
    return this.http.get<showWorkers>(this.availableWorkers)
  }

  sendTwilioCallActivityStatus(object: any): Observable<sendTwilioCallActivityStatus> {
    return this.http.post<sendTwilioCallActivityStatus>(this.inboxFocusApiPath + "/callLogging", object)
  }

  getIntelligentDefaultsData(client: string | null, providerNumber: string) {
    return firstValueFrom(this.http.get<guidanceInterface>(this.intelligentDefaultsData + `${client}` + "&phoneNumber=" + `${providerNumber}`)
      .pipe(responseData => {
        return responseData;
      }))
  }

  sendTwilioCallAuditStatus(object: any) {
    return firstValueFrom(this.http.post(this.inboxFocusApiPath + "/inboundCallAudit", object)
      .pipe(responseData => {
        return responseData;
      }))
  }

  createOutboundCall(workerId: string, from: string, to: string) {
    return firstValueFrom(this.http.post(this.createOutboundCallApiPath, {
      workerId: workerId,
      from: from,
      to: to,
    }).pipe(responseData => {
      return responseData;
    })
    )
  }

  validateOutboundCall(phoneNumber: string, id: any): Observable<validateOutboundCall> {
    return this.http.post<validateOutboundCall>(this.inboxFocusApiPath + "/validate-outbound-call", { phoneNumber: phoneNumber, orgEntityId: id })
  }

  outboundCallTask(workerSid: string, customerNumber: string, twilioNumber: string | undefined, workflowSid: string | undefined): Observable<null> {
    const obj = { workerSid: workerSid, customerNumber: customerNumber, twilioNumber: twilioNumber, workflowSid: workflowSid }
    return this.http.post<null>(this.twilioService + "/outbound-call-task", obj);
  }

  outboundConferenceCall(obj: any):Observable<outboundConferenceCallInterface> {
    return this.http.post<outboundConferenceCallInterface>(this.twilioService + "/outbound-conference-call", obj)
  }

  private smsResponse: Subject<any> = new Subject<any>();
  getLatestActivityLogs(response: any) {
    this.smsResponse.next(response);
  }

  getLatestActivityLogsData() {
    return this.smsResponse.asObservable();
  }
  GetDataTriggerEvent(dataTriggerEventId) {
    let url = `/api/v1/platform/data-trigger-event/${dataTriggerEventId}`;
    return this.http.get(url, { headers: this.headers });
  }

  getInboxTaskList(workQueueId: string, params: any): Observable<any> {
    return this.http.get<any>(this.inboxTaskListAPI + workQueueId, { params: params })
  }

  postInboxTaskListPull(body: any): Observable<any> {
    return this.http.post<any>(this.inboxTaskListPullAPI, body)
  }

  getTaskTypeList(id: string): Observable<any> {
    return this.http.get<any>(this.inboxTasksTypeListAPI + id);
  }

  getTaskPriorityList(codeCategoryId: string): Observable<any> {
    return this.http.get<any>(this.inboxTasksPriorityListAPI + codeCategoryId);
  }
  getInboxPatientEngagementFeedList(orgEntityId: string, offset: number, filterObj: PEFFilterModel): Observable<any> {
    let params = {};
    params = { orgEntityId: orgEntityId, offset: offset, limit: 10, ...filterObj};
    return this.http.get<any>(this.inboxPatientEngagementFeedAPI, {params: params});
  }

  getPEFProgramList( offset: number, query: string): Observable<any> {
    let params = {};
    params = { offset: offset, limit: 10, query: query};
    return this.http.get<any>(this.inboxPatientEngagementFeedAPI + '/programs', {params: params});
  }
  getInboxPatientEngagementFeedDetails(id: string, type: string, dateCreated: string, orgEntityId: string): Observable<any> {
    let params = {};
    if(type == 'SMS' || type == 'EMAIL'){
       params = {id:orgEntityId, type: type, date: dateCreated}
    }else{
      params = {id:id, type: type}
    }
    return this.http.get<any>(this.inboxPatientEngagementFeedAPI + '/details',  {params: params});
  }

  getPatientTaskGuidanceDataBasedOnTaskType(taskTypeId: string): Observable<any> {
    return this.http.get<any>(this.inboxPatientTaskGuidanceDataAPI + '/taskType/' + taskTypeId);
  }

  updateTaskStatus(interventionId: string, bodyData: any): Observable<any> {
    return this.http.put<any>(`${this.inboxChangeTaskStatusAPI}/${interventionId}`, bodyData,
      {
        headers: new HttpHeaders({
          'content-type': 'application/json;charset=UTF-8',
        })
      }
    )
  };

  updateBookmarkTaskStatus(interventionId: string, bodyData: any): Observable<any> {
    return this.http.put<any>(`${this.inboxUpdateTaskBookmarkStatusAPI}/${interventionId}`, bodyData, 
      {
        headers: new HttpHeaders({
          'content-type': 'application/json;charset=UTF-8',
        })
      }
    )
  };
  
  getInboxTaskProviders(params: any): Observable<any> {
    return this.http.get<any>(this.inboxTaskDetailsAPI + '/providers', {params: params});
  }

  getInboxTasksWorkQueues(params: any): Observable<any> {
    return this.http.get<any>(this.inboxTaskDetailsAPI + '/my-work-queues', {params: params});
  }
  putTransferTasksWorkQueues(body: any): Observable<any> {
    return this.http.put<any>(this.inboxTaskDetailsAPIV2 + '/transfer-task',body);
  }
  putTransferToProviders(body: any): Observable<any> {
    return this.http.put<any>(this.inboxTaskDetailsAPIV2 + '/transfer-task-provider',body);
  }
}
