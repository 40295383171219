import { Injectable } from "@angular/core";
import { BehaviorSubject, Observable, of } from "rxjs";
import { io, Socket } from "socket.io-client";
import { v4 } from "uuid";
import { ToastrService } from "ngx-toastr";
import { callerDetail } from "../apps/focus/inbox/calls/interface";
import { callAuditParameters } from "../apps/focus/inbox/calls/search-or-associate-patient-modal/search-or-associate-patient-modal-interface";
import { customWindow } from "src/custom";
import { DefaultEventsMap } from "@socket.io/component-emitter";
import { reservation } from "src/app/apps/focus/common-components/call-slideup-popover/interface"
import { uiClientsConfig } from 'config';

interface SocketTwilio {
  socketTwilio: any,
  isTryingReconnection: boolean,
  retryCount: number,
  retryConnectionTimeout: number,
  maxRetryAttempts: number
}

export interface participantObject {
  displayName: string,
  participantType: string,
  profilePictureUrl: string,
  oauthUserId: string,
  orgEntityId: string,
  agentInitials: string,
  isMuted: boolean,
  isHold: boolean
}
@Injectable({
  providedIn: "root"
})

export class LocalServiceService {

  constructor(private toastr: ToastrService) { }

  window: customWindow = window;
  providerId: string = "";
  callAuditObject: callAuditParameters = {
    providerId: '',
    callSid: '',
    fromNumber: '',
    toNumber: '',
    rawCallData: {},
    isOutboundCall: false
  };
  associatedOrgId: string = "";
  workQueueIdValue: string = ""
  oauthUserId: string = "";
  subDomain = window.location.host.split("-focus")[0];
  participantObject = new BehaviorSubject<participantObject[]>([]);
  participantObj = this.participantObject.asObservable();
  private hasUnsavedChanges = false;
  private hasImportPatientUnsavedChanges = false;          // by default should be false
  private oauthUserName = new BehaviorSubject<string>('');
  oauthUserNameString = this.oauthUserName.asObservable();

  private importPatientUnsavedChanges = new BehaviorSubject<boolean>(false);
  importPatientUnsavedChanges$ = this.importPatientUnsavedChanges.asObservable();
  setOAuthUserName(tab: string) {
    this.oauthUserName.next(tab);
  }
  getOAuthUserName(): string {
    return this.oauthUserName.value;
  }

  private allowNavigation = new BehaviorSubject<boolean>(true);
  currentValueForNavigation = this.allowNavigation.asObservable();
  campaignHasUnsavedChanges(newValue: boolean) {
    this.allowNavigation.next(!newValue);
  }
  setCampaignUnsavedChanges(status: boolean): void {
    this.hasUnsavedChanges = status;
  }
  setImportPatientUnsavedChanges(status: boolean): void {
    this.hasImportPatientUnsavedChanges = status;
    this.importPatientUnsavedChanges.next(this.hasImportPatientUnsavedChanges)
  }

  getImportPatientUnsavedChanges(): boolean {
    return this.hasImportPatientUnsavedChanges;
  }
  getCampaignUnsavedChanges(): boolean {
    return this.hasUnsavedChanges;
  }

  private searchorAssociatePatient = new BehaviorSubject<any>({});
  afterCloseSearchorAssociatePatient = this.searchorAssociatePatient.asObservable();
  resultSearchorAssociatePatient(result: any = {}) {
    this.searchorAssociatePatient.next(result);
  }

  private enableCallButtons = new BehaviorSubject<boolean>(false);
  currentValue = this.enableCallButtons.asObservable();
  enableCallBtns(newValue: boolean) {
    this.enableCallButtons.next(newValue);
  }

  private messageSource = new BehaviorSubject<string>("");
  currentString = this.messageSource.asObservable();
  changeMessage(newString: string) {
    this.messageSource.next(newString);
  }

  private patientProfile = new BehaviorSubject<string>("");
  viewPatientOrgEntityId = this.patientProfile.asObservable();
  viewPatientProfile(orgEntityId: string) {
    this.patientProfile.next(orgEntityId);
  }

  private inboxTabIndex = new BehaviorSubject<number>(0);
  redirectToCallsTab = this.inboxTabIndex.asObservable();
  setInboxTabIndex(tab: number) {
    this.inboxTabIndex.next(tab);
  }
  getInboxTabIndex(): number {
    return this.inboxTabIndex.value;
  }

  private workQueueId = new BehaviorSubject<string>("");
  workQueueId$ = this.workQueueId.asObservable();
  setWorkQueueId(index: string) {
    this.workQueueId.next(index);
  }
  getWorkQueueId(): string {
    return this.workQueueId.value;
  }

  private taskTypeId = new BehaviorSubject<string>("");
  taskTypeId$ = this.taskTypeId.asObservable();
  setTaskTypeId(index: string) {
    this.taskTypeId.next(index);
  }
  getTaskTypeId(): string {
    return this.taskTypeId.value;
  }

  private associatedPatientDetail = new BehaviorSubject<any>({});
  currentAssociatedPatientDetail = this.associatedPatientDetail.asObservable();
  setAssociatedPatientDetail(patientDetail: any) {
    this.associatedPatientDetail.next(patientDetail);
  }

  private isTwilioCallsEnabled = new BehaviorSubject<boolean>(false);
  isCurrentlyEnabled = this.isTwilioCallsEnabled.asObservable();
  showTwilioCallBtn(isCall: boolean) {
    this.isTwilioCallsEnabled.next(isCall);
  }

  private closeModals = new BehaviorSubject<string>("");
  isCallLeaveOrHangup = this.closeModals.asObservable();
  callLeavedOrHangedup(status: string) {
    this.closeModals.next(status);
  }

  private holdStatus = new BehaviorSubject<boolean>(false);
  isCallOnHold = this.holdStatus.asObservable();
  changeHoldStatus(bool: boolean) {
    this.holdStatus.next(bool);
  }

  private holdStatusForUI = new BehaviorSubject<boolean>(false);
  isCallOnHoldUI = this.holdStatusForUI.asObservable();
  changeHoldStatusUI(bool: boolean) {
    this.holdStatusForUI.next(bool);
  }
  private accessDeniedPage = new BehaviorSubject<boolean>(false);
  public accessDeniedPage$ = this.accessDeniedPage.asObservable();

  showAccessDeniedPage() {
    this.accessDeniedPage.next(true);
  }

  hideAccessDeinedPage() {
    this.accessDeniedPage.next(false);
  }

  setUserProviderId(id: string) {
    this.providerId = id;
  }
  getUserProviderId(): Observable<string> {
    return of(this.providerId);
  }

  setAssociatedPatientOrgId(orgEntityId: string) {
    this.associatedOrgId = orgEntityId
  }

  getAssociatedPatientOrgId() {
    return of(this.associatedOrgId);
  }

  setCallAuditParameters(callSid: string | undefined, fromNumber: string | undefined, toNumber: string | undefined, rawCallData: object | undefined, providerId: string | undefined, isOutboundCall: boolean | undefined) {
    this.callAuditObject.callSid = callSid;
    this.callAuditObject.fromNumber = fromNumber;
    this.callAuditObject.toNumber = toNumber;
    this.callAuditObject.rawCallData = rawCallData;
    this.callAuditObject.providerId = providerId;
    this.callAuditObject.isOutboundCall = isOutboundCall;
  }

  getCallAuditParameters() {
    return of(this.callAuditObject);
  }

  private showPsychograpicGuidance = new BehaviorSubject<string>("");
  psychographicSegment = this.showPsychograpicGuidance.asObservable();
  currentPsychographicSegement(segment: string) {
    this.showPsychograpicGuidance.next(segment);
  }

  /** when a twilio call tries to connect */
  private twilioCallIncomingObject = new BehaviorSubject<{ reservation: reservation, callStatus: string, __this?: any }>({ reservation: {} as reservation, callStatus: "", __this: {} });
  receiveTwilioCallNotification = this.twilioCallIncomingObject.asObservable();
  sendTwilioCallNotification(incomingTwilioCallObject: { reservation: reservation, callStatus: string, __this?: any }) {

    if (incomingTwilioCallObject?.reservation?.task?.attributes) {

      if (incomingTwilioCallObject.reservation.task.attributes.from?.includes("+117+")) {
        incomingTwilioCallObject.reservation.task.attributes.from = incomingTwilioCallObject.reservation.task.attributes.from.split("+117+")[0];
      }
      if (incomingTwilioCallObject.reservation.task.attributes.caller?.includes("+117+")) {
        incomingTwilioCallObject.reservation.task.attributes.caller = incomingTwilioCallObject.reservation.task.attributes.caller.split("+117+")[0];
      }
    }
    this.twilioCallIncomingObject.next(incomingTwilioCallObject);
  }

  private twilioUserStatus = new BehaviorSubject<string>("");
  receiveTwilioUserStatus = this.twilioUserStatus.asObservable();
  sendUserStatus(userStatusObj: string) {
    this.twilioUserStatus.next(userStatusObj)
  }

  private dialPadPermission = new BehaviorSubject<boolean>(false);
  receiveDialPadPermission = this.dialPadPermission.asObservable();
  sendDialPadPermission(permission: boolean) {
    this.dialPadPermission.next(permission)
  }

  socketTwilioParent: SocketTwilio = {
    socketTwilio: null,
    isTryingReconnection: false,
    retryCount: 0,
    retryConnectionTimeout: 10000,
    maxRetryAttempts: 15
  };

  initiateTwilioSocketConnection(oauthUserId: string) {
    this.oauthUserId = oauthUserId;
    if (typeof this.window["microServiceTwilioSocketConnection"] !== "undefined") {
      if (typeof this.window["microServiceTwilioSocketConnection"].removeAllListeners === "function") {
        this.window["microServiceTwilioSocketConnection"].removeAllListeners();
      }
    }

    var twilioErrorFunction = () => {
      console.log(`Trying to reconnect to socket.io, current retry count: ${this.socketTwilioParent.retryCount + 1}`);
      this.socketTwilioParent.isTryingReconnection = true;
      if (this.socketTwilioParent.isTryingReconnection && (this.socketTwilioParent.retryCount < this.socketTwilioParent.maxRetryAttempts)) {
        this.socketTwilioParent.retryCount++;
        this.socketTwilioParent.retryConnectionTimeout = this.socketTwilioParent.retryCount > 10 ?
          (this.socketTwilioParent.retryConnectionTimeout + this.socketTwilioParent.retryConnectionTimeout) : this.socketTwilioParent.retryConnectionTimeout;
        console.log(`Attempting reconnection for socket.io in next ${(this.socketTwilioParent.retryConnectionTimeout) / 1000} seconds, next retry count: ${this.socketTwilioParent.retryCount + 1}`);
        setTimeout(() => {
          msTwilioSocketConnection.io.open((error) => {
            if (error) {
              twilioErrorFunction();
            }
          });
        }, this.socketTwilioParent.retryConnectionTimeout);
      } else {
        console.log(`Stopped socket.io connection try after ${this.socketTwilioParent.retryCount + 1} attempts.`)
      }
    }

    var msTwilioSocketConnection: Socket<DefaultEventsMap, DefaultEventsMap> = this.window["microServiceTwilioSocketConnection"] = {} as Socket<DefaultEventsMap, DefaultEventsMap>;
    this.window["microServiceTwilioSocketConnection"] = msTwilioSocketConnection = io(`https://${this.subDomain}-twilio.${uiClientsConfig.reservedUrlDomain}`, {
      transports: ["websocket"],
      query: {
        responseServer: "twilio",
        oauthUserId: oauthUserId
      },
      reconnection: false,
    });
    msTwilioSocketConnection.on("connect", () => {
      console.log("Connected to Socket.IO server", msTwilioSocketConnection.id);
      window.removeEventListener("online", twilioErrorFunction);
      this.socketTwilioParent.isTryingReconnection = false;
      this.socketTwilioParent.retryCount = 0;
      this.socketTwilioParent.retryConnectionTimeout = 10000;
      this.socketTwilioParent.maxRetryAttempts = 15;
    });
    msTwilioSocketConnection.on("disconnect", () => {
      console.log("Disconnected from Socket.IO server");
      if (!navigator.onLine) {
        window.addEventListener("online", twilioErrorFunction.bind(this));
      }
      else {
        twilioErrorFunction();
      }
    });
    msTwilioSocketConnection.io.on("error", () => {
      twilioErrorFunction();
    });

    msTwilioSocketConnection.io.on("close", twilioErrorFunction);

    msTwilioSocketConnection.on("outbound-busy", (data: any) => {
      console.log("outbound-busy", data);

      this.toastr.info("Called number is busy, please try again after sometime.");

      if (this.window["windowTwilioWorkerInstance"] && this.window["windowTwilioWorkerInstance"].device &&
        typeof this.window["windowTwilioWorkerInstance"].device.disconnectAll === "function") {
        this.window["windowTwilioWorkerInstance"].device.disconnectAll();
      }
    });

    msTwilioSocketConnection.on("conference-notify", (data: any) => {
      console.log("conference-notify", data);
      const agentDetails: participantObject[] | undefined = Object.values(data)
        .map((
          { displayName, participantType, profilePictureUrl, oauthUserId, orgEntityId, agentInitials, isMuted, isHold }: any
        ) => {
          if (participantType === "customer") {
            this.changeHoldStatusUI(isHold);
          }

          if (participantType === "worker") {
            return {
              displayName,
              participantType,
              profilePictureUrl,
              oauthUserId,
              orgEntityId,
              agentInitials,
              isMuted
            };
          }

          if (participantType === "external") {
            return {
              displayName,
              participantType,
              profilePictureUrl: "",
              oauthUserId: "",
              orgEntityId: "",
              agentInitials: "EA",
              isMuted
            };
          }
          return undefined;
        })?.filter(Boolean) as participantObject[];

      this.participantObject.next(agentDetails);
    });

  }

  disconnectInternalSocketConnection() {
    console.log("Before internal socket disconnection");
    this.window["microServiceTwilioSocketConnection"]?.disconnect();
    this.window["microServiceTwilioSocketConnection"] = undefined;
    this.socketTwilioParent = {
      socketTwilio: null,
      isTryingReconnection: false,
      retryCount: 0,
      retryConnectionTimeout: 10000,
      maxRetryAttempts: 15
    };
  }

  setSessionId() {
    let session = sessionStorage.getItem("sessionId");
    if (!session) {
      sessionStorage.setItem("sessionId", v4());
    }
  }

  groupByDate(data) {
    const groupedData = {};

    data.forEach(item => {
        const date = item.createdDateTimeUtc.split('T')[0]; 
        if (!groupedData[date]) {
            groupedData[date] = []; 
        }
        groupedData[date].push(item); 
    });

    let result = Object.keys(groupedData).map(date => ({
        date: date,
        data: groupedData[date]
    }));

    result = result.sort((a, b) => new Date(b.date).getTime() - new Date(a.date).getTime());

    result.forEach(group => {
        group.data = group.data.sort((a, b) => new Date(b.createdDateTimeUtc).getTime() - new Date(a.createdDateTimeUtc).getTime());
    });

    return result;
}

}
