<header class="row site-header mx-0">
  <div class="site-identity">
    <img class="upfront-logo" src="../../../assets/images/upfront.svg" alt="">
    <a class="upfront-focus-text" [ngStyle]="{ 'pointer-events': userStatus === 'On Call' ? 'none' : 'auto' }"
      href="{{appsRedirectUrl}}">UPFRONT FOCUS</a>
    <div class="search-holder"><i class="fa fa-search cursor-pointer" aria-hidden="true" (click)="searchPatient()"></i>
      <input type="text" name="search" maxlength="50"
        placeholder="Search for a patient by ID, First Name, Last Name, Email, DOB, Phone Number"
        [(ngModel)]="searchString" (keydown.enter)="searchPatient()" (ngModelChange)="searchInput($event)">
    </div>
  </div>
  <nav class="site-navigation">
    <ul class="nav-items">
      <li routerLink="/focus" routerLinkActive="active" [routerLinkActiveOptions]="{exact:true}">Home</li>
      <li routerLink="/focus/patients" *ngIf="allPermission && allPermission.GetPatientDetails"
        routerLinkActive="active">Patients</li>
      <li routerLink="/focus/inbox" *ngIf="allPermission && allPermission.InboxFocusGet" routerLinkActive="active">Inbox
      </li>
      <li routerLink="/focus/campaign" *ngIf="allPermission && allPermission.PostPatientFileUpload" routerLinkActive="active">Campaign</li>
      <li routerLink="/focus/dashboard/{{defaultDashboardRoute}}"
        *ngIf="allPermission && allPermission.FocusDashboardGet && (allSubPermission.FocusDashboardLiveVoice || allSubPermission.FocusDashboardDigitalEngagement || allSubPermission.FocusDashboardRealtime)"
        routerLinkActive="active">Dashboards</li>
      <!-- <li routerLink="/focus/realTimeDashboard" routerLinkActive="active">Statistics</li> -->
      <li routerLink="/focus/reports" routerLinkActive="active" *ngIf="allPermission && allPermission.FocusReportsGet">Reports</li>
      <li>
        <span id="matMenuId" mat-span [matMenuTriggerFor]="menu"
          [ngClass]="{'mat-menu-disabled-class': !isMatMenuAllowedToOpen}" style="display: inline-flex;color: #53565a;">
          <div>
            <p class="mb-0 text-right multiline-ellipsis text-capitalize" style="padding-right:10px;">{{oauthUserName}}
            </p>
            <p class="mb-0 text-right" [style.color]="userStatusColor"
              style="font-size: 9px; text-transform: capitalize; line-height: 15px;font-weight: 700;letter-spacing: 1px;padding-right: 10px;">
              {{userStatus}}</p>
          </div>
          <div style=" position: relative;
            display: inline-block;">
            <img [src]="oauthUserProfilePictureUrl" alt="" class="profileImage" style="width: 43px;height: 43px;" />
          </div>
          <i class="fa fa-caret-down" style="margin-left: 5px;"></i>
        </span>
        <mat-menu class="mat-content translate-reset navbar-dropdown" #menu="matMenu">
          <div style="border: none;">
            <div style="float: left">
              <img [src]="oauthUserProfilePictureUrl" alt=""
                style="width: 45px;border-radius: 50%;margin: 5px;padding: 6px;" />
            </div>

            <div style="display: inline-block;padding: 6px;">
              <h4 style="margin: 0;text-align: left;"> {{oauthUserName}} </h4>
              <span class="profileEmail"> {{oauthEmailAddress}} </span>
            </div>
          </div>
          <div class="row mx-0 w-100 mat-inner-content"
            style="display:flex;border-bottom: 1px solid #DEDEDB;border-top: 1px solid #DEDEDB; padding: 16px 0;">
            <div class="col-12">
              <span class="fw-700" style="color:var(--dark-blue-grey);">Status</span>
              <div mat-menu-item class="status-title d-flex align-items-center"
                *ngIf="isTwilioWorkerEnabled && orgEntityId"
                (click)="providerChannelAvailability('TAKING_CALLS',userStatus)">
                <span class="status-indicator" style="background-color: Green;">
                  <i class="fa fa-check" aria-hidden="true"></i>
                </span> Taking Calls
              </div>
              <div mat-menu-item class="status-title d-flex align-items-center"
                *ngIf="isTwilioWorkerEnabled && orgEntityId && allPermission.OutboundDailpadGet"
                (click)="providerChannelAvailability('MAKING_CALLS',userStatus)">
                <span class="status-indicator" style="background-color: var(--focus-theme-green);">
                  <i class="fa fa-plus" aria-hidden="true"></i>
                </span> Making Calls
              </div>
              <div mat-menu-item class="status-title d-flex align-items-center"
                (click)="providerChannelAvailability('PERSONAL',userStatus)">
                <span class="status-indicator" style="background-color: #42A3BA;"></span> Personal
              </div>
              <div mat-menu-item class="status-title d-flex align-items-center"
                (click)="providerChannelAvailability('LUNCH',userStatus)">
                <span class="status-indicator" style="background-color: #42A3BA;"></span> Lunch
              </div>
              <div mat-menu-item class="status-title d-flex align-items-center"
                (click)="providerChannelAvailability('BREAK',userStatus)">
                <span class="status-indicator" style="background-color: #42A3BA;"></span> Break
              </div>
              <div mat-menu-item class="status-title d-flex align-items-center"
                (click)="providerChannelAvailability('TRAINING',userStatus)">
                <span class="status-indicator" style="background-color:#C4314B ;">
                  <i class="fa fa-minus" aria-hidden="true"></i>
                </span> Training
              </div>
              <div mat-menu-item class="status-title d-flex align-items-center"
                (click)="providerChannelAvailability('MEETING',userStatus)">
                <span class="status-indicator" style="background:#A4B1BC"></span> Meeting
              </div>
              <div mat-menu-item class="status-title d-flex align-items-center"
                (click)="providerChannelAvailability('RECEIVING_ASSISTANCE_OTHERS',userStatus)">
                <span class="status-indicator" style="background:#A4B1BC"></span> Receiving Assistance
              </div>
              <div mat-menu-item class="status-title d-flex align-items-center"
                (click)="providerChannelAvailability('GIVING_ASSISTANCE_OTHERS',userStatus)">
                <span class="status-indicator" style="background:#A4B1BC"></span> Giving Assistance
              </div>
              <div mat-menu-item class="status-title d-flex align-items-center"
                (click)="providerChannelAvailability('TASKING_OTHERS',userStatus)">
                <span class="status-indicator" style="background:#A4B1BC"></span> Tasking
              </div>
            </div>
          </div>
          <footer class="col-sm-12 mat-footer" (click)="$event.stopPropagation();">
            <button (click)="logout()" class="col-4"
              style="background-color:#22464F;border-radius:5px;height:30px;margin-right: 4%;border:none"><span
                style="color:#FFFFFF;font-size:14px;font-weight:500;">Logout</span></button>
          </footer>
        </mat-menu>

      </li>
    </ul>
  </nav>
</header>